import React from 'react';
import './PresentationsPage.css'; // Import CSS file for component styles

const Presentation = ({ title, description, videoId }) => {
  return (
    <div className="presentation">
      <h3>{title}</h3>
      <p>{description}</p>
      <div className="video-container">
        <iframe
          title={title}
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

const PresentationsPage = () => {
  const presentations = [
    {
      title: '如何谈一场成功的恋爱',
      description: '爱俭师母 教我们 如何谈一场成功的恋爱',
      videoId: 'eRZTkUfTDi0'
    },
    {
      title: '如何选择配偶？',
      description: '爱俭师母 带您 选择配偶',
      videoId: '4mStrqCVyYw'
    },
    {
      title: '才女如何收获幸福爱情',
      description: '爱俭师母的讲座 "才女如何收获幸福爱情"',
      videoId: 'cxbnvN1LrJU'
    },
    {
      title: '爱如何变成了伤害',
      description: '爱俭师母给我们的教诲"爱如何变成了伤害"',
      videoId: 'lYR5KDWemeE'
    },
    {
      title: '交友与婚姻系列一 打破女子无才便是德的魔咒',
      description: '爱俭师母"交友与婚姻系列一 打破女子无才便是德的魔咒"',
      videoId: '0zOFyg0OJJE'
    },
    {
      title: '快乐成长系列二： 风浪就完全平静了——情绪管理',
      description: '爱俭师母"快乐成长系列二： 风浪就完全平静了——情绪管理"',
      videoId: 'exc_d2nhMU0'
    },
    // Add more presentations as needed  refer=>https://www.youtube.com/@minjuli199
  ];

  return (
    <div className="presentations-page">
      <h2 className="page-title">YouTube Presentations</h2>
      <div className="presentations-list">
        {presentations.map((presentation, index) => (
          <Presentation key={index} {...presentation} />
        ))}
      </div>
    </div>
  );
};

export default PresentationsPage;
