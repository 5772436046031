import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const FeaturedCoursesEvents = () => {
    return (
        <section className="featured-courses-events">
            <Container>
                <h2>特色课程和活动</h2>
                <Row>
                    <Col md={4}>
                        <Card>
                            <Card.Img variant="top" src="course1.jpg" />
                            <Card.Body>
                                <Card.Title>讲座 1</Card.Title>
                                <Card.Text>
                                    爱俭师母"交友与婚姻系列一 打破女子无才便是德的魔咒"
                                </Card.Text>
                                <Card.Link href="/presentation">了解更多</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Img variant="top" src="course2.jpg" />
                            <Card.Body>
                                <Card.Title>讲座 2</Card.Title>
                                <Card.Text>
                                    爱俭师母"快乐成长系列二： 风浪就完全平静了——情绪管理"
                                </Card.Text>
                                <Card.Link href="/presentation">了解更多</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Img variant="top" src="event1.jpg" />
                            <Card.Body>
                                <Card.Title>活动 1</Card.Title>
                                <Card.Text>
                                    爱俭师母给我们的教诲"爱如何变成了伤害"
                                </Card.Text>
                                <Card.Link href="/presentation">了解更多</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default FeaturedCoursesEvents;
